




















import { defineComponent, onMounted } from "@vue/composition-api";
import router from "@/router";

export default defineComponent({
    name: "WelcomePageTMF",
    setup() {
        onMounted(() => {
            if (router.currentRoute.params.voucherCode) localStorage.setItem('voucherCode', router.currentRoute.params.voucherCode)
        })
    }
})
